import http from '@/http-common'

const home = async () => {
  return await http
    .get('/report')
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const reports = async filter => {
  return await http
    .get('/report/reports', {
      params: filter,
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

export default {
  home,
  reports
}
