<template>
  <div>
    <div class="d-flex align-center">
      <v-btn icon @click="prev">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      {{ v | month }}
      <v-spacer></v-spacer>
      <v-btn icon @click="next" :disabled="!canGoNext">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <v-card :loading="loading">
      <v-calendar
        v-model="v"
        :weekdays="weekday"
        type="month"
        ref="calendar"
        @change="emitChange"
        :events="events"
        :event-color="i => i.color"
        event-height="40"
        @click:event="showEvent"
      >
        <template v-slot:event="{ event }">
          <p class="mb-0 pl-1">R$ {{ event.amount | beautifulNumber }}</p>
          <p class="mb-0 pl-1">{{ event.name }}</p>
        </template>
      </v-calendar>
    </v-card>
    <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
      <v-card color="grey lighten-4" :min-width="$vuetify.breakpoint.smAndUp ? 600 : null" flat v-if="selectedOpen">
        <v-toolbar :color="selectedEvent.color" dark>
          <v-toolbar-title class="justify-space-between align-center">
            R$ {{ selectedEvent.amount | beautifulNumber }}
            <v-chip class="ml-2" x-small :color="selectedEvent.color" label>
              {{ selectedEvent.name }}
            </v-chip>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-actions class="justify-center">
          <v-btn text @click="selectedOpen = false"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment'
moment.locale('pt-br')

export default {
  data: () => ({
    v: moment().format('YYYY-MM-DD'),
    weekday: [0, 1, 2, 3, 4, 5, 6],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),
  methods: {
    next() {
      if (moment(this.v).endOf('month').isAfter(moment())) return
      this.$refs.calendar?.next()
    },
    prev() {
      this.$refs.calendar?.prev()
    },
    emitChange({ start, end }) {
      this.$emit('input', { start: moment(start.date).toDate(), end: moment(end.date).toDate() })
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
  },
  computed: {
    canGoNext() {
      if (moment(this.v).endOf('month').isAfter(moment())) return false
      return true
    },
    events() {
      if (!this.report) return []
      return this.report.payments
        .filter(i => ['Paid', 'Pending'].includes(i.status))
        .reduce((acc, i) => {
          const date = moment(i.payDate).format('YYYY-MM-DD')
          const status = i.status

          const index = acc.findIndex(j => j.date === date && j.status === status)

          const amount = parseFloat(i.value) + parseFloat(i.fees) + parseFloat(i.penalty) - parseFloat(i.discount)
          if (index === -1)
            acc.push({
              date,
              status,
              amount,
              payments: [i],
            })
          else {
            acc[index].amount += amount
            acc[index].payments.push(i)
          }
          return acc
        }, [])
        .map(item => {
          return {
            name: `${item.status == 'Paid' ? 'Pago' : 'Pendente'}`,
            start: moment(item.date).toDate(),
            end: moment(item.date).toDate(),
            color: item.status == 'Paid' ? 'success' : 'warning',
            timed: false,
            amount: item.amount,
            payments: item.payments,
          }
        })
    },
  },
  filters: {
    month(v) {
      return moment(v).format('MMMM YYYY')
    },
    beautifulNumber(value) {
      value = parseFloat(value)
      const opts = [
        ['K', 1000],
        ['M', 1000000],
        ['B', 1000000000],
        ['T', 1000000000000],
      ]
      let i = opts.length - 1
      while (i >= 0) {
        if (value >= opts[i][1]) {
          return (value / opts[i][1]).toFixed(1) + opts[i][0]
        }
        i--
      }
      return value.toFixed(2).replace('.', ',')
    },
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    report: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style></style>
