<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <calendar v-model="date" :loading="loading" :report="report" />
      </v-col>
      <v-col cols="12" md="6"> <RentalRevenue :report="report" /> </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import REPORT from '@/services/report'

import Calendar from '@/components/report/Calendar.vue'
import RentalRevenue from '@/components/report/RentalRevenue.vue'

export default {
  data: () => ({
    date: null,
    reports: {},
    loading: false,
    timeOut: null,
    forceRefresh: false,
  }),
  methods: {
    async getReport(filter) {
      try {
        const currentMonth = moment(filter.start).format('YYYY-MM')
        const start = moment(filter.start).startOf('day').toISOString()
        const end = moment(filter.end).endOf('day').toISOString()
        const response = await REPORT.reports({ start, end })
        this.reports[currentMonth] = response
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
        this.forceRefresh = true
        this.$nextTick(() => {
          this.forceRefresh = false
        })
      }
    },
    queryStart(d) {
      this.loading = true
      if (this.timeOut) clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.getReport(d)
      }, 500)
    },
  },
  watch: {
    date(val) {
      if (!val) return
      const currentMonth = moment(val.start).format('YYYY-MM')
      if (!this.reports[currentMonth]) this.queryStart(val)
    },
  },
  computed: {
    report() {
      if (this.forceRefresh) return null
      if (!this.date) return null
      const currentMonth = moment(this.date.start).format('YYYY-MM')
      return this.reports[currentMonth] || null
    },
  },

  components: { Calendar, RentalRevenue },
}
</script>

<style></style>
