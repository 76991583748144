<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="text-no-wrap">Total de Receitas</span>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-subtitle class="mb-4">
      <span class="text-no-wrap">Receitas com Locação</span>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col v-for="(d, i) in data" :key="i" cols="12" sm="6" class="d-flex align-center">
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ d.title }}
            </p>
            <span class="font-weight-semibold text-2xl me-1 mb-2">R$ {{ d.total | beautifulNumber }}</span>
            <span class="amount text-xs mb-2 success--text" v-if="!d.hidePercentage">{{ d.percentage }}</span>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    types: {
      total: {
        title: 'Total',
        hidePercentage: true,
      },
      amount: {
        title: 'Operacional',
      },
      penalty: {
        title: 'Multas',
      },
      fees: {
        title: 'Juros',
      },
    },
  }),
  computed: {
    data() {
      if (!this.report) return []
      const reduced = this.report.payments.reduce(
        (acc, item) => {
          const amount = parseFloat(item.value)
          const fees = parseFloat(item.fees)
          const penalty = parseFloat(item.penalty)
          const discount = parseFloat(item.discount)
          const total = amount + fees + penalty - discount

          acc.amount += amount - discount
          acc.fees += fees
          acc.penalty += penalty
          acc.total += total
          return acc
        },
        {
          total: 0,
          amount: 0,
          fees: 0,
          penalty: 0,
        },
      )

      return Object.entries(reduced).map(([key, value]) => {
        return {
          ...this.types[key],
          total: value,
          percentage: `${((value / reduced.total || 0) * 100).toFixed(2)}%`,
        }
      })
    },
  },
  props: {
    report: {
      type: Object,
      default: null,
    },
  },
  filters: {
    beautifulNumber(value) {
      value = parseFloat(value)
      const opts = [
        ['K', 1000],
        ['M', 1000000],
        ['B', 1000000000],
        ['T', 1000000000000],
      ]
      let i = opts.length - 1
      while (i >= 0) {
        if (value >= opts[i][1]) {
          return (value / opts[i][1]).toFixed(1) + opts[i][0]
        }
        i--
      }
      return value.toFixed(2).replace('.', ',')
    },
  },
}
</script>
